import { readonly } from 'vue';
import ChocoPayWidget from 'choco-pay-widget';
import {
	requestGeoPermissionStatus,
	isLocationEnabled,
	pay as _pay,
	GeoStatus
} from 'choco-app';
import { IState, state } from './state';
import { KEYS, IPaymentOptions, isPlatforms } from './types';
import { getCurrentPosition } from './functions';
import { auth } from '@/api';
import i18n from '@/utils/plugins/i18n';
import providers from '@/utils/plugins/di';
import QrPayPlugin from '@/utils/plugins/qr-pay';
import { DOCUMENT_TITLE, ALL_TOWNS, VISIBLE_TOWNS } from '@/utils/constants';
import { EmptyCb } from '@/types';
import { ICoordinates } from '@/types/interfaces/common';

type ServiceChangeHandler = (last: string, current: string) => unknown;

const isWebView = () => {
	return !!window.RahmetApp;
};

const getPaymentEnv = (): string => {
	const appEnv = import.meta.env.VITE_ENV;

	if (['local', 'staging'].includes(appEnv)) {
		return 'stage';
	}

	if (appEnv === 'development') {
		return 'test';
	}

	return 'production';
};

const storage = window.localStorage;
const defaultTitle = i18n.global.t(DOCUMENT_TITLE);
const GEO_DISABLED_ERROR = new Error('Location is disabled globally');
const paymentEnv = getPaymentEnv();
const serviceChangeHandlers: ServiceChangeHandler[] = [];

const attachToServiceChange = (handler: ServiceChangeHandler) => {
	if (!serviceChangeHandlers.includes(handler)) {
		serviceChangeHandlers.push(handler);
	}
};

const detachFromServiceChange = (handler: ServiceChangeHandler) => {
	const index = serviceChangeHandlers.indexOf(handler);
	if (index >= 0) {
		serviceChangeHandlers.splice(index, 1);
	}
};

const notifyOnServiceChange = (last: string, current: string) => {
	for (const handler of serviceChangeHandlers) {
		handler(last, current);
	}
};

const getTownById = (id: number) => ALL_TOWNS.find(town => +town.id === id);

const getTownByName = (name: string) => {
	return ALL_TOWNS.find(town => town.title === name);
};

const setTownId = (id: number): void => {
	state.townId = id;
	storage.setItem(KEYS.TOWN_ID, `${id}`);
};

const setTownByName = (name?: string) => {
	const value = name || '';
	const town = getTownByName(value);

	if (town) {
		setTownId(+town.id);
	}
};

const containsVisibleCity = (city?: string) => {
	const name = city || '';
	return VISIBLE_TOWNS.some(t => t.title === name);
};

const setPlatform = (value: string): void => {
	if (isPlatforms(value)) {
		state.platform = value;
		storage.setItem(KEYS.PLATFORM, value);
	}
};

const setVersion = (value: string): void => {
	state.version = value;
	storage.setItem(KEYS.VERSION, value);
};

const setDeviceId = (value: string): void => {
	state.deviceId = value;
	storage.setItem(KEYS.DEVICE_ID, value);
};

const setTitle = (title: string): void => {
	state.title = title;
	const titles = [defaultTitle];

	if (title) {
		titles.unshift(title);
	}

	document.title = [...new Set(titles)].join(' - ');
};

const setGeoStatus = (value: GeoStatus): void => {
	state.geoStatus = value;
};

const fetchWebViewGeoStatus = (): Promise<GeoStatus> => {
	return requestGeoPermissionStatus().then(status => {
		setGeoStatus(status);
		return status;
	});
};

const setCoordinates = (coords: ICoordinates | null): void => {
	state.coordinates = coords;
};

const hasCoordinates = (): boolean => !!state.coordinates;

const fetchCoordinates = (): Promise<void> => {
	return getCurrentPosition().then(setCoordinates);
};

let geoRejectedCallback: EmptyCb = () => {};

const setGeoRejectedCallback = (callback: EmptyCb) => {
	if (callback && typeof callback === 'function') {
		geoRejectedCallback = callback;
	}
};

const onGeoDeny = (): void => {
	setGeoStatus(GeoStatus.denied);
	setCoordinates(null);
};

const initCoordinates = async (): Promise<boolean> => {
	try {
		let isEnabled = true;

		if (isWebView()) {
			isEnabled = isLocationEnabled();
			await fetchWebViewGeoStatus();
		}

		const isDenied = state.geoStatus === GeoStatus.denied;

		if (isDenied || !isEnabled) {
			throw GEO_DISABLED_ERROR;
		}

		await fetchCoordinates();
		return true;
	} catch (error) {
		setGeoStatus(GeoStatus.denied);
		return false;
	}
};

const initCoordinatesIfNotGranted = async (callback: EmptyCb = () => {}) => {
	try {
		if (isWebView() && !isLocationEnabled()) {
			throw new Error('Location is disabled globally');
		}

		await fetchCoordinates();
		setGeoStatus(GeoStatus.granted);
		callback();
	} catch {
		geoRejectedCallback();
		onGeoDeny();
	}
};

const doIfCoordinatesExist = (callback: EmptyCb) => {
	if (hasCoordinates()) {
		return callback();
	}

	return initCoordinatesIfNotGranted(callback);
};

/**
 * Обработчик оплаты в WebView
 */
const handleWebViewPayment = (backlink: string): Promise<boolean> => {
	return _pay(backlink).then(status => status === 'success');
};

/**
 * Обработчик оплаты в браузере
 */
const handleWebPayment = (options: IPaymentOptions): Promise<boolean> => {
	const href = window.location.href;
	const origin = new URL(href).origin;

	const successUrl = options.successUrl
		? `${origin}${options.successUrl}`
		: origin;

	const failureUrl = options.failureUrl
		? `${origin}${options.failureUrl}`
		: href;

	return auth.getTrackId().then(response => {
		const params = {
			track_id: response.data.id,
			paymentUrl: options.backlink,
			environment: paymentEnv,
			sucessUrl: successUrl,
			failureUrl: failureUrl,
			lang: i18n.global.locale.value,
			includeOrderId: options.includeOrderId,
			innerRedirect: options.innerRedirect,
			is_chocobalance_disabled: options.is_chocobalance_disabled
		};

		if (options.displayType === 'kaspi') {
			const QrPayPluginItem = new QrPayPlugin(params);
			QrPayPluginItem.initPayment();
		} else {
			const ChocoPayWidgetItem = new ChocoPayWidget(params);
			ChocoPayWidgetItem.initPayment();
		}

		return false;
	});
};

/**
 * Оплата
 */
const pay = (options: IPaymentOptions): Promise<boolean> => {
	if (isWebView()) {
		return handleWebViewPayment(options.backlink);
	}

	return handleWebPayment(options);
};

/**
 * Изменение сервиса по id (localStorage)
 */
const setServiceById = (id: string, strict = false) => {
	state.service = providers.application.getServiceById(id, strict);
	localStorage.setItem(KEYS.SERVICE, id);
};

/**
 * Изменение сервиса и очистка филиалов
 */
const changeService = (id: string, strict = false) => {
	if (state.service.id !== id) {
		const last = state.service.id;
		const current = id;

		setServiceById(id, strict);
		notifyOnServiceChange(last, current);
	}
};

const ClientService = {
	state: readonly(state) as IState,
	isWebView,
	getTownById,
	getTownByName,
	setTownId,
	setTownByName,
	containsVisibleCity,
	setPlatform,
	setVersion,
	setDeviceId,
	setTitle,
	hasCoordinates,
	setGeoRejectedCallback,
	initCoordinates,
	initCoordinatesIfNotGranted,
	doIfCoordinatesExist,
	pay,
	attachToServiceChange,
	detachFromServiceChange,
	changeService
};

export { ClientService };
