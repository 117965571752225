import { readonly, reactive } from 'vue';
import { AuthService } from '@/services/auth';
import { CartService } from '@/services/cart';
import { menu, menuPromos, smartLock } from '@/api';
import { DEFAULT_TEMP_DATA } from './constants';
import {
	addPromoToMenu,
	addFrequentToMenu,
	getTranslatedMenu,
	actualizeCategoryNames
} from './functions';
import { ITempFilialData, KEYS, IMenuState, IMenuService } from './types';
import {
	IBarcodeProduct,
	IProduct,
	IAdditiveGroup,
	IProductsCategory
} from '@/types/interfaces/menu';
import { ADDITIVE_STATUSES } from '@/types/enums/menu';

interface IState extends IMenuState {
	tempTerminals: number[];
	tempFilialData: ITempFilialData;
}

interface IService extends IMenuService {
	state: IState;
	actualizeMenu: () => IProductsCategory[];
	setTempTerminals: (terminals: number[]) => void;
	clearTempTerminals: () => void;
	setTempDataByKey: <K extends keyof ITempFilialData>(
		key: K,
		value: ITempFilialData[K]
	) => void;
	initTempData: (data: ITempFilialData) => void;
	resetTempData: () => void;
	fetchProductByBarcode: (
		filialId: number,
		barcode: string
	) => Promise<IBarcodeProduct | undefined>;
	unlockMicroMarket: (filialId: number) => Promise<void>;
}

const state = reactive<IState>({
	menu: [],
	tempTerminals: JSON.parse(localStorage.getItem(KEYS.TEMP_TERMINALS)!) || [],
	recentTerminalId: 0,
	additiveGroupsCache: {
		[ADDITIVE_STATUSES.DEFAULT]: {},
		[ADDITIVE_STATUSES.FAVOURITE]: {}
	},
	promos: [],
	promoProducts: [],
	externalId: undefined,
	tempFilialData:
		JSON.parse(sessionStorage.getItem(KEYS.MENU_TEMP_DATA)!) ||
		DEFAULT_TEMP_DATA
});

let controller = new AbortController();

const actualizeMenu = () => {
	actualizeCategoryNames(state.menu);
	return state.menu;
};

/**
 * Получение категорий продуктов
 */
const fetchProductCategories = (filialId: number, abort?: AbortController) => {
	return menu.getMenuCategories(filialId, abort).then(response => {
		return {
			categories: response.data.relationships,
			externalId: response.data.attributes.external_id
		};
	});
};

/**
 * Получение часто заказываемых продуктов
 */
const fetchFrequentProducts = (
	filialId: number,
	abort?: AbortController
): Promise<IProduct[]> => {
	const apiMethod = AuthService.isAuthorized()
		? menu.getFavouriteProductsAuth
		: menu.getFavouriteProducts;

	return apiMethod(filialId, abort).then(response => response.data);
};

/**
 * Получение акции
 */
const fetchPromo = (
	filialId: number,
	userId: string | null,
	abort?: AbortController
) => {
	if (state.promos.length && state.promos[0].attributes.filialId === filialId) {
		return Promise.resolve({
			promos: state.promos,
			promoProducts: state.promoProducts
		});
	}

	return menuPromos.getPromos(filialId, userId, abort).then(response => {
		state.promos = response.data;
		state.promoProducts = response.included || [];
		return {
			promos: state.promos,
			promoProducts: state.promoProducts
		};
	});
};

/**
 * Получение меню с акцией и любимыми блюдами
 */
const fetchMenu = async (
	filialId: number,
	userId: string | null,
	strict = false,
	abort?: AbortController
) => {
	if (state.recentTerminalId === filialId && state.menu.length && !strict) {
		return {
			categories: getTranslatedMenu(state.menu),
			promos: state.promos,
			externalId: state.externalId
		};
	}

	const promises = [
		fetchProductCategories(filialId, abort), // основное меню
		fetchFrequentProducts(filialId, abort), // любимые блюда
		fetchPromo(filialId, userId, abort) // акция
	];
	const [menu, frequents, promoData] = await Promise.allSettled(promises).then(
		// @ts-ignore
		res => res.map(r => r.value)
	);

	let categories = menu?.categories || [];
	const externalId = menu?.externalId;
	const { promos, promoProducts } = promoData || {
		promos: [],
		promoProducts: []
	};

	if (frequents && frequents.length) {
		categories = addFrequentToMenu(categories, frequents);
	}

	if (promos.length) {
		categories = addPromoToMenu(promos, promoProducts, categories);
	}

	state.recentTerminalId = filialId;
	state.menu = categories;
	state.externalId = externalId;

	return {
		categories,
		promos,
		externalId
	};
};

/**
 * Получение групп дополнений из кэша
 */
const getAdditiveGroups = (
	productId: number,
	status: ADDITIVE_STATUSES = ADDITIVE_STATUSES.DEFAULT
) => {
	return (
		state.additiveGroupsCache[status][productId] || { additive_groups: [] }
	);
};

/**
 * Сохранение групп дополнений, чтобы повторно не делать запрос
 */
const setAdditiveGroups = (
	productId: number,
	groups: IAdditiveGroup[],
	selected: number[] | undefined
) => {
	state.additiveGroupsCache[ADDITIVE_STATUSES.DEFAULT][productId] = {
		additive_groups: groups,
		last_selected: undefined
	};

	state.additiveGroupsCache[ADDITIVE_STATUSES.FAVOURITE][productId] = {
		additive_groups: groups,
		last_selected: selected
	};
};

/**
 * Получение групп дополнений и помещение их в кэш
 */
const fetchAdditiveGroups = async (
	product: IProduct,
	filialId: number,
	serviceType: number,
	status: ADDITIVE_STATUSES = ADDITIVE_STATUSES.DEFAULT
) => {
	controller.abort();
	controller = new AbortController();

	if (!product.attributes.has_additive_categories) {
		return { additive_groups: [] };
	}

	const cached = state.additiveGroupsCache[status][product.id];

	// у дополнений по умолчанию нет выбранных элементов
	if (cached && status === ADDITIVE_STATUSES.DEFAULT) {
		return cached;
	}

	// а вот тут стоит проверять на выбранные элементы
	if (cached && cached.last_selected !== undefined) {
		return cached;
	}

	try {
		let apiMethod = menu.getProductAdditives;
		if (AuthService.isAuthorized() && status === ADDITIVE_STATUSES.FAVOURITE) {
			apiMethod = menu.getProductAdditivesAuth;
		}

		const response = await apiMethod(
			filialId,
			product.id,
			serviceType,
			controller
		).then(response => response.data);

		let groups = response.additive_groups;
		const discountPercentage = state.promos.length
			? state.promos[0].attributes.discount_percentage
			: 0;

		if (discountPercentage) {
			groups = groups.map(additive => {
				return {
					...additive,
					relationships: additive.relationships.map(group => {
						group.attributes.price =
							group.attributes.price * (1 - discountPercentage / 100);

						return group;
					})
				};
			});
		}

		setAdditiveGroups(product.id, groups, response.last_selected);
		return { additive_groups: groups, last_selected: response.last_selected };
	} catch (error: any) {
		if (error?.code === 'ERR_CANCELED') {
			return undefined;
		}

		throw error;
	}
};

const clearMenu = () => (state.menu = []);

/**
 * Очистка кэша групп дополнений
 */
const clearAdditiveGroups = () => {
	state.additiveGroupsCache[ADDITIVE_STATUSES.DEFAULT] = {};
	state.additiveGroupsCache[ADDITIVE_STATUSES.FAVOURITE] = {};
};

/**
 * Получение рекоммендованных продуктов, связанных с корзиной
 */
const fetchRecommendedProducts = (): Promise<IProduct[]> => {
	return new Promise((resolve, reject) => {
		const filialId = CartService.state.cart.filial_id;
		const productIds = CartService.state.cart.products
			.filter(p => !p.is_unavailable)
			.map(p => p.product_id);

		if (!filialId || !productIds.length) {
			return resolve([]);
		}

		return menu
			.getRecommendedProducts(filialId, productIds)
			.then(response => resolve(response))
			.catch(error => reject(error));
	});
};

/**
 * Получение продукта по штрихкоду
 */
const fetchProductByBarcode = async (filialId: number, barcode: string) => {
	try {
		const { data } = await menu.getProductByBarcode(filialId, barcode);
		return data;
	} catch {
		return undefined;
	}
};

/**
 * Сохранение временных терминалов в localStorage
 */
const setTempTerminals = (terminals: number[]) => {
	state.tempTerminals = terminals;
	localStorage.setItem(KEYS.TEMP_TERMINALS, JSON.stringify(terminals));
};

/**
 * Очистка временных терминалов из localStorage
 */
const clearTempTerminals = () => {
	if (!state.tempTerminals.length) {
		return;
	}

	state.tempTerminals = [];
	localStorage.removeItem(KEYS.TEMP_TERMINALS);
};

/**
 * Сохранение временных данных по ключу в меню
 */
const setTempDataByKey = <K extends keyof ITempFilialData>(
	key: K,
	value: ITempFilialData[K]
) => {
	if (value && value !== state.tempFilialData[key]) {
		state.tempFilialData[key] = value;
		sessionStorage.setItem(
			KEYS.MENU_TEMP_DATA,
			JSON.stringify(state.tempFilialData)
		);
	}
};

/**
 * Очистка временных данных в меню
 */
const resetTempData = () => {
	state.tempFilialData = DEFAULT_TEMP_DATA;
	sessionStorage.removeItem(KEYS.MENU_TEMP_DATA);
};

/**
 * Инициализация временных данных в меню
 */
const initTempData = (data: ITempFilialData) => {
	const hasFilials = data.filialId && state.tempFilialData.filialId;
	const notSameFilials = data.filialId !== state.tempFilialData.filialId;

	if (hasFilials && notSameFilials) {
		resetTempData();
	}

	const keys = Object.keys(data) as (keyof ITempFilialData)[];
	keys.forEach(key => {
		setTempDataByKey(key, data[key]);
	});
};

const MenuService: IService = {
	state: readonly(state) as IState,
	actualizeMenu,
	fetchPromo,
	fetchMenu,
	getAdditiveGroups,
	fetchAdditiveGroups,
	clearMenu,
	clearAdditiveGroups,
	fetchRecommendedProducts,
	fetchProductByBarcode,
	setTempTerminals,
	clearTempTerminals,
	setTempDataByKey,
	initTempData,
	resetTempData,
	unlockMicroMarket: smartLock.unlock
};

export { MenuService };
export * from './types';
export * from './constants';
export * from './functions';
export * from './models';
