import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { BillService } from '@/services/bill';
import { NavigatorService } from '@/services/navigator';
import providers from '@/utils/plugins/di';

export default async function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const filialId = String(to.query.filial_id);
	const { table_id: tableId, main_terminal_id: mainTerminalId } = to.query;

	const handleOnError = () => {
		if (filialId) {
			return next({
				name: 'Bill',
				params: {
					filial_id: filialId
				},
				query: {
					...to.query,
					error: 'error'
				}
			});
		}

		next();
	};

	if (tableId && filialId && mainTerminalId) {
		try {
			providers.application.showLoader();

			const isCheckPullingEnabled = await NavigatorService.fetchFilial(
				+filialId
			).then(data => data.filial.is_check_pulling_enabled);

			if (isCheckPullingEnabled) {
				await BillService.fetchBillInfo(+tableId, filialId);
				next({
					name: 'Bill',
					params: {
						filial_id: filialId
					},
					query: to.query
				});
			} else {
				next();
			}
		} catch (error) {
			console.log('Failed to fetch bill info:', error);
			handleOnError();
		} finally {
			providers.application.hideLoader();
		}
	} else {
		next();
	}
}
