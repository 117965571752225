import { client } from '../default.client';
import type {
	ApiProxyResponse,
	ApiProxyJSONApiResponse
} from '../types/interfaces';
import type {
	PrivateRewardBalance,
	PrivateRewardBalancePayload
} from '@/types/interfaces/balance';

interface CurrencyAmount {
	KZT: number;
}

interface Balance {
	bonus: CurrencyAmount;
	money: CurrencyAmount;
}

export const balance = {
	async getPrivateRewardBalance(payload: PrivateRewardBalancePayload) {
		const response = await client.get<{
			data: ApiProxyJSONApiResponse<PrivateRewardBalance>;
		}>('/ladder-go/api/v1/balance/calculate-info', { params: payload });

		return response.data.data;
	},
	async getUserBalance() {
		const response =
			await client.get<ApiProxyResponse<Balance>>('/balance/user');
		return response.data;
	}
};
