<template>
	<div class="min-h-full flex flex-col viewport-max-width">
		<!-- Шапка -->
		<BaseHeader
			:title="title"
			:show-button-back="showButtonBack"
			fixed
			@back="goToPrevPage"
		/>

		<!-- Инпут поиска -->
		<div class="flex items-center px-4 pt-4 pb-2">
			<SearchField
				ref="searchEl"
				:model-value="searchValue"
				:placeholder="placeholder"
				:disabled="isSearchDisabled"
				class="w-full"
				@update:modelValue="onSearchUpdate"
				@click="onSearchClick"
				@focus="onSearchFocus"
				@submit="submitSearch"
			>
				<Transition name="fade">
					<MapButtonLink v-if="showMapButton" data-testid="search-map-button" />
				</Transition>
			</SearchField>

			<button
				v-if="areSearchElementsActive"
				class="ml-1 font-sirius text-base font-medium text-grey-dark"
				data-testid="search-cancel"
				@click="onSearchCancel"
			>
				{{ $t('common.cancel') }}
			</button>
		</div>

		<!-- Свитчер -->
		<BaseServiceSwitcher
			v-if="isSwitcherVisible"
			class="pt-4"
			@select="onServiceSelect"
		/>

		<!-- При активном поиске -->
		<template v-if="areSearchElementsActive">
			<!-- История -->
			<template v-if="!searchValue">
				<SearchHistory
					v-if="history.length"
					:values="history"
					:class="{ 'pt-2': !isSwitcherVisible }"
					@select="onSearchSelect"
					@clear="clearHistory"
				/>

				<div
					v-else
					class="font-sirius text-lg font-black"
					:class="isSwitcherVisible ? 'p-5' : 'p-4'"
				>
					{{ $t('search.searchLabel') }}
				</div>
			</template>

			<!-- Автозаполнение -->
			<SearchAutocomplete
				v-else-if="autocomplete.length"
				:values="autocomplete"
				@select="onSearchSelect"
			/>

			<!-- Область для закрытия поиска при нажатии -->
			<div class="flex-1" @click="submitSearch"></div>
		</template>

		<div v-if="$slots.default">
			<div v-show="!areSearchElementsActive">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import BaseHeader from '@/components/base/BaseHeader';
import BaseServiceSwitcher from '@/components/base/BaseServiceSwitcher';
import SearchField from '@/components/search/SearchField';
import SearchHistory from '@/components/search/SearchHistory';
import SearchAutocomplete from '@/components/search/SearchAutocomplete';
import MapButtonLink from '@/components/map/MapButtonLink';
import { ClientService } from '@/services/client';
import {
	SearchService,
	SearchHistory as SearchHistoryService
} from '@/services/search';
import { useSearchAnalytics } from '@/services/analytics';
import { useNavigation } from '@/utils/composables';
import { injectStrict } from '@/utils/functions';
import { ProvidersKey } from '@/types/symbols';

const DEFAULT_PLACEHOLDER_KEY = 'search.searchPlaceholder';

/**
 * @module layouts/SearchLayout
 * @vue-computed {string} title - Заголовок
 * @vue-computed {boolean} showButtonBack - Показывает кнопку "Назад"
 * @vue-computed {boolean} showMapButton - Показывает кнопку перехода в карты
 * @vue-computed {string[]} history - История поиска
 */
export default {
	name: 'SearchLayout',
	components: {
		BaseHeader,
		BaseServiceSwitcher,
		SearchField,
		SearchHistory,
		SearchAutocomplete,
		MapButtonLink
	},
	setup() {
		const providers = injectStrict(ProvidersKey);
		const config = providers.application.getCustomConfig();

		const route = useRoute();
		const { t } = useI18n();
		const { trackOnConfirmSearch } = useSearchAnalytics();

		const title = computed(() => ClientService.state.title);
		const showButtonBack = computed(() => {
			return !['Home', 'Brands'].includes(route.name);
		});

		const showMapButton = computed(() => {
			return !['Map', 'Search', 'MenuSearch'].includes(route.name);
		});

		let searchService = SearchService;
		let historyService = SearchHistoryService;
		const history = computed(() => historyService.state.history);
		const autocomplete = ref([]);
		const initialText = route.query.text || '';
		const searchValue = ref(initialText);
		let lastSubmittedSearchValue = initialText;

		const placeholder = ref(t(DEFAULT_PLACEHOLDER_KEY));
		const searchEl = ref(null);
		const isSwitcherActive = ref(false);
		const isSearchDisabled = ref(false);
		const areSearchElementsActive = ref(false);
		let searchClickHandler = null;
		let searchUpdateHandler = null;
		let searchSubmitHandler = null;
		let searchCancelHandler = null;
		let searchFocusHandler = null;
		let clientServiceChangeHandler = null;
		let timer = null;

		const { goToPrevPage } = useNavigation();

		const isSwitcherVisible = computed(() => {
			if (!isSwitcherActive.value) {
				return false;
			}

			return true;
		});

		/**
		 * Получение данных для автозаполнения
		 * @param {value}
		 */
		const fetchAutocomplete = async value => {
			if (value) {
				autocomplete.value = await searchService.getAutocomplete(
					value,
					config?.brand_id
				);
			}
		};

		/**
		 * Вызов обработчика обновления поиска при наличии
		 * @param {string} value - значение поиска
		 */
		const execSearchUpdateHandler = value => {
			if (searchUpdateHandler) {
				searchUpdateHandler(value);
			}
		};

		/**
		 * Вызов обработчика отмены поиска при наличии
		 * @param {boolean} value
		 */
		const execSearchCancelHandler = value => {
			if (searchCancelHandler) {
				searchCancelHandler(value);
			}
		};

		/**
		 * Вызов обработчика смены сервиса
		 * @param {string} value
		 */
		const execClientServiceChangeHandler = value => {
			if (clientServiceChangeHandler) {
				clientServiceChangeHandler(value);
			}
		};

		/**
		 * Сброс сервиса поиска
		 */
		const resetSearchService = () => {
			searchService = SearchService;
		};

		/**
		 * Сброс сервиса истории поиска
		 */
		const resetHistoryService = () => {
			historyService = SearchHistoryService;
		};

		/**
		 * Показать элементы поиска
		 */
		const showSearchElements = () => (areSearchElementsActive.value = true);
		/**
		 * Скрыть элементы поиска
		 */
		const hideSearchElements = () => (areSearchElementsActive.value = false);

		/**
		 * Активация свитчера
		 */
		const activateSwitcher = bool => (isSwitcherActive.value = bool);

		/**
		 * Отключение инпута поиска
		 */
		const disableSearch = bool => (isSearchDisabled.value = bool);

		/**
		 * Подтверждение поиска
		 */
		const submitSearch = () => {
			lastSubmittedSearchValue = searchValue.value;
			historyService.addHistoryValue(searchValue.value);
			trackOnConfirmSearch({ query: searchValue.value });

			if (searchSubmitHandler) {
				return searchSubmitHandler();
			}
		};

		/**
		 * При изменении значения поиска
		 * @param {string} value
		 */
		const onSearchUpdate = value => {
			searchValue.value = value;

			if (timer) {
				clearTimeout(timer);
				timer = null;
			}

			if (!value) {
				autocomplete.value = [];
				execSearchUpdateHandler(value);
			} else {
				timer = setTimeout(() => {
					timer = null;
					execSearchUpdateHandler(value);
					fetchAutocomplete(value);
				}, 300);
			}
		};

		/**
		 * При выборе значения поиска
		 * @param {string} value
		 */
		const onSearchSelect = value => {
			searchValue.value = value;
			historyService.addHistoryValue(value);
			execSearchUpdateHandler(value);
			submitSearch();
		};

		/**
		 * При отмене поиска
		 */
		const onSearchCancel = () => {
			if (searchValue.value === lastSubmittedSearchValue) {
				return execSearchCancelHandler(true);
			}

			searchValue.value = lastSubmittedSearchValue;
			fetchAutocomplete(lastSubmittedSearchValue);
			execSearchUpdateHandler(lastSubmittedSearchValue);
			execSearchCancelHandler(false);
		};

		/**
		 * При клике на инпут поиска
		 */
		const onSearchClick = () => {
			if (searchClickHandler) {
				return searchClickHandler();
			}
		};

		/**
		 * При фокусе инпута поиска
		 */
		const onSearchFocus = () => {
			if (searchValue.value && !autocomplete.value.length) {
				fetchAutocomplete(searchValue.value);
			}

			if (searchFocusHandler) {
				searchFocusHandler();
			}
		};

		/**
		 * При выборе сервиса
		 */
		const onServiceSelect = async id => {
			ClientService.changeService(id);
			execClientServiceChangeHandler(id);

			if (!isSearchDisabled.value) {
				fetchAutocomplete(searchValue.value);

				if (!areSearchElementsActive.value) {
					submitSearch();
				}
			}
		};

		/**
		 * Очистка истории
		 */
		const clearHistory = () => {
			historyService.clearHistory();
			searchEl.value.focusInput();
		};

		const focusSearch = () => {
			if (searchEl.value) {
				searchEl.value.focusInput();
			}
		};

		const blurSearch = () => {
			if (searchEl.value) {
				searchEl.value.blurInput();
			}
		};

		provide('SearchLayout', {
			search: searchValue,
			focusSearch,
			blurSearch,
			showSearchElements,
			hideSearchElements,
			activateSwitcher,
			disableSearch,
			setPlaceholder: value => (placeholder.value = value),
			setHistoryService: service => (historyService = service),
			setSearchService: service => (searchService = service),
			setSearchClickHandler: func => (searchClickHandler = func),
			setSearchUpdateHandler: func => (searchUpdateHandler = func),
			setSearchSubmitHandler: func => (searchSubmitHandler = func),
			setSearchCancelHandler: func => (searchCancelHandler = func),
			setSearchFocusHandler: func => (searchFocusHandler = func),
			setClientServiceChangeHandler: func => (clientServiceChangeHandler = func), // prettier-ignore
			resetSearchService,
			resetHistoryService,

			// Note: вызывать перед уничтожением route
			resetAll: () => {
				searchValue.value = '';
				lastSubmittedSearchValue = '';
				placeholder.value = t(DEFAULT_PLACEHOLDER_KEY);
				searchClickHandler = null;
				searchUpdateHandler = null;
				searchSubmitHandler = null;
				searchCancelHandler = null;
				searchFocusHandler = null;
				activateSwitcher(false);
				disableSearch(false);
				resetSearchService();
				resetHistoryService();
			}
		});

		return {
			title,
			showButtonBack,
			showMapButton,
			history,
			autocomplete,
			searchValue,
			placeholder,
			searchEl,
			isSwitcherActive,
			isSearchDisabled,
			areSearchElementsActive,
			goToPrevPage,
			isSwitcherVisible,
			execSearchCancelHandler,
			submitSearch,
			onSearchUpdate,
			onSearchSelect,
			onSearchCancel,
			onSearchClick,
			onSearchFocus,
			onServiceSelect,
			clearHistory
		};
	}
};
</script>
