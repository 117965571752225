import { client } from '../default.client';
import { getBillAdapter } from '../adapters/bill';
import type { ApiProxyResponse } from '../types/interfaces';
import { AxiosResponse } from 'axios';
import type { IBillDetails } from '@/types/interfaces/orders';
import type {
	IBill,
	IFilialToken,
	BillPreorderPayload,
	IPreorder
} from '@/types/interfaces/bill';

export const bill = {
	async getBillDetails(preorderId: string | number): Promise<IBillDetails> {
		const response = await client.get<AxiosResponse<IBill>>(
			`/smart-receipt/v1/receipt/preorder/${preorderId}`
		);

		return getBillAdapter(response.data.data);
	},
	async getBill(tableId: number, terminalId: string): Promise<IBillDetails> {
		const response = await client.get<AxiosResponse<IBill>>(
			`smart-receipt/v1/receipt`,
			{
				params: {
					table_id: tableId,
					terminal_id: terminalId
				}
			}
		);

		return getBillAdapter(response.data.data);
	},
	async getFilialToken(filialId: string | number) {
		const response = await client.get<ApiProxyResponse<IFilialToken>>(
			`partner/filial/pure/${filialId}`
		);

		return response.data;
	},
	async createPreorder(
		payload: BillPreorderPayload
	): Promise<ApiProxyResponse<IPreorder>> {
		const response = await client.post(
			'smart-receipt/v1/receipt/preorder',
			payload
		);

		return response.data;
	}
};
