<template>
	<section>
		<slot name="pre"></slot>
		<div
			v-for="lang in SUPPORTED_LANGS"
			:key="lang.code"
			class="flex items-center justify-between px-4 py-3 w-full mb-3 last:mb-0"
		>
			<UiRadio
				v-model="currentLang"
				class="w-full"
				name="lang"
				:value="lang.code"
				:reversal="true"
				@click="setLang(lang.code)"
			>
				<div class="font-sirius text-lg font-black mr-auto flex items-center">
					{{ lang.name }}
				</div>
			</UiRadio>
		</div>

		<UiButton type="primary" @click="applyAndClose">
			{{ $t('action.apply') }}
		</UiButton>
	</section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import UiRadio from '@/components/ui/UiRadio.vue';
import UiButton from '@/components/ui/UiButton.vue';
import { useLanguageAnalytics, LangChangeSources } from '@/services/analytics';
import { Locale, SUPPORTED_LANGS } from '@/utils/plugins/i18n';
import { useLanguage } from '@/utils/composables';
import { LANG_STORAGE_KEY } from '@/utils/plugins/i18n/constants';

interface Props {
	source: LangChangeSources;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (e: 'close'): void }>();

const { locale } = useI18n();
const { updateLanguage } = useLanguage();
const { trackLangChanged } = useLanguageAnalytics();
const currentLang = ref(locale.value);

const logEvent = (lang: Locale) =>
	trackLangChanged({
		lang,
		source: props.source
	});

const setLang = (lang: Locale) => {
	updateLanguage(lang, true);
	logEvent(lang);
};

const applyAndClose = () => {
	if (!localStorage.getItem(LANG_STORAGE_KEY)) {
		updateLanguage(currentLang.value, true);
	}

	emit('close');
};
</script>
