import { round } from '@/utils/functions';
import {
	IProduct,
	MenuCategories,
	ProductAttr,
	ProductAttrRaw,
	RawMenuCategories
} from '@/types/interfaces/menu';

const getDiscountText = (price: number, oldPrice: number) => {
	return `-${round(((oldPrice - price) / oldPrice) * 100)}%`;
};

function productAttrAdapter(attributes: ProductAttrRaw): ProductAttr {
	const { reward_id, old_price, price, ...rest } = attributes;
	const promoLimit = attributes.promo_limit || 9999;
	const promoDayLimit = attributes.promo_day_limit || 9999;

	return {
		...rest,
		promo_id: reward_id || null,
		price: old_price ? old_price || 0 : price,
		promo_price: old_price ? price : null,
		discount: old_price ? getDiscountText(price, old_price) : null,
		sizes:
			attributes.sizes && attributes.sizes.length ? attributes.sizes : null,
		promo_limit: reward_id ? promoLimit : null,
		promo_day_limit: reward_id ? promoDayLimit : null
	};
}

const productsAdapter = (products: IProduct<ProductAttrRaw>[]): IProduct[] => {
	return products.map(product => {
		return {
			...product,
			attributes: productAttrAdapter(product.attributes)
		};
	});
};

const menuCategoriesAdapter = (data: RawMenuCategories): MenuCategories => {
	return {
		...data,
		relationships: data.relationships.map(category => {
			return {
				...category,
				relationships: productsAdapter(category.relationships)
			};
		})
	};
};

export { productAttrAdapter, productsAdapter, menuCategoriesAdapter };
