import { client } from '../default.client';
import type {
	RyadomAddCartPayload,
	RyadomCart
} from '@/types/interfaces/ryadom';

export const ryadom = {
	async addToCart(payload: RyadomAddCartPayload, deviceId: string) {
		const res = await client.put('/cart/v1/guest/cart', payload, {
			headers: {
				'X-Device-Id': deviceId
			}
		});
		return res.data;
	},
	async addToRyadomAuth(payload: RyadomAddCartPayload) {
		const res = await client.put('/cart/v1/cart', payload);
		return res.data;
	},
	async clearCart(warehouseId: string, deviceId: string) {
		const res = await client.post(
			'/cart/v1/guest/cart/clear',
			{
				warehouse_id: warehouseId
			},
			{
				headers: {
					'X-Device-Id': deviceId
				}
			}
		);
		return res.data;
	},
	async clearCartAuth(warehouseId: string) {
		const res = await client.post('/cart/v1/cart/clear', {
			warehouse_id: warehouseId
		});
		return res.data;
	},
	async getCart(warehouseId: string, deviceId: string): Promise<RyadomCart> {
		const res = await client.get(
			`/cart/v1/guest/cart?warehouse_id=${warehouseId}`,
			{
				headers: {
					'X-Device-Id': deviceId
				}
			}
		);
		return res.data.data;
	},
	async getCartAuth(warehouseId: string): Promise<RyadomCart> {
		const res = await client.get(`/cart/v1/cart?warehouse_id=${warehouseId}`);
		return res.data.data;
	}
};
