import { client } from '../default.client';
import type { ApiProxyResponse } from '../types/interfaces';
import type { IReward } from '@/types/interfaces/loyalty';

export const loyalty = {
	async getReward(amount: number, terminalId: number) {
		const response = await client.get<ApiProxyResponse<IReward>>(
			`loyalty/reward-calculate/${amount}`,
			{
				params: {
					terminal_id: terminalId
				}
			}
		);
		return response.data;
	}
};
