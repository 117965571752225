import App from './App.vue';
import { createApp } from 'vue';
import UiBottomSheet from '@/components/ui/UiBottomSheet.vue';
import UiModal from '@/components/ui/UiModal.vue';
import { Analytics } from '@/services/analytics';
import { YandexMetrikaService } from '@/services/yandex-metrika';
import { SentryService } from '@/services/sentry';
import { VendorAnalytics } from '@/services/vendor-analytics';
import { clickOutside, onlyLetter, onlyNumber } from '@/utils/directives';
import { loaderPlugin } from '@/utils/plugins/loader';
import { notificationPlugin } from '@/utils/plugins/notification';
import { imageProxyPlugin } from '@/utils/plugins/image-proxy';
import { deliveryPlugin } from './utils/plugins/delivery';
import { DependencyInjectionPlugin } from '@/utils/plugins/di/plugin-installer';
import providers from '@/utils/plugins/di';
import { thousandSeparatorPlugin } from '@/utils/plugins/thousand-separator';
import router from './router';
import i18n from './utils/plugins/i18n';
import currency from './utils/plugins/currency';
import './assets/styles/styles.scss';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(loaderPlugin);
app.use(notificationPlugin);
app.use(imageProxyPlugin);
app.use(DependencyInjectionPlugin);
app.use(deliveryPlugin);
app.use(thousandSeparatorPlugin);
app.use(currency);

Analytics.init();

const config = providers.application.getCustomConfig();
if (config?.analytics) {
	VendorAnalytics.init(config.analytics);
}

YandexMetrikaService.init(router);
SentryService.init(app, router);

app.directive('click-outside', clickOutside);
app.directive('only-number', onlyNumber);
app.directive('only-letter', onlyLetter);

app.component('UiBottomSheet', UiBottomSheet);
app.component('UiModal', UiModal);

app.mount('#app');
