import { IState, IBaseDeliveryService, KEYS } from './types';
import { PRIVATE_RESIDENCE } from './constants';
import { chocofood } from '@/api';
import { ICoordinates } from '@/types/interfaces/common';
import { ICfUserAddress } from '@/types/interfaces/chocofood';

let redirectUrl = sessionStorage.getItem(KEYS.REDIRECT_URL_KEY) || '';

/**
 * Возвращает адрес с префиксом страны и города
 */
const getAddressWithPrefix = (city = 'Алматы', address: string) => {
	return `Казахстан, ${city}, ${address}`;
};

/**
 * Функция-фабрика по созданию базы сервиса доставки
 */
export const createBaseService = (state: IState): IBaseDeliveryService => {
	/**
	 * Возвращает список адресов по введенному адресу
	 */
	const fetchAddressSuggestions = (address: string) => {
		return chocofood.getSuggestions(
			getAddressWithPrefix(state.tempAddress.city, address)
		);
	};

	/**
	 * Возвращает адрес по координатам
	 */
	const fetchAddressByCoordinates = (coordinates: ICoordinates) => {
		return chocofood.getReverseGeocode(coordinates);
	};

	/**
	 * Проверяет является ли адрес частным
	 */
	const isPrivateResidence = () => {
		return state.tempAddress.apartment === PRIVATE_RESIDENCE;
	};

	/**
	 * Устанавливает временный город
	 */
	const setTempTown = (town: string) => (state.tempAddress.city = town);

	/**
	 * Получение текста адреса
	 */
	const getAddressString = (
		address: ICfUserAddress,
		keys: Array<keyof ICfUserAddress> = ['city', 'street', 'building']
	) => {
		return keys
			.map(k => address[k])
			.filter(Boolean)
			.join(', ');
	};

	/**
	 * Имеется ли доступный адрес
	 */
	const hasAvailableAddress = () => !!state.activeAddress.id;

	/**
	 * Получение url для редиректа после создания/редактирования адреса
	 */
	const getRedirectUrl = () => redirectUrl;

	/**
	 * Назначение url для редиректа
	 */
	const setRedirectUrl = (url: string) => {
		redirectUrl = url;
		sessionStorage.setItem(KEYS.REDIRECT_URL_KEY, url);
	};

	/**
	 * Удаление url для редиректа
	 */
	const clearRedirectUrl = () => {
		redirectUrl = '';
		sessionStorage.removeItem(KEYS.REDIRECT_URL_KEY);
	};

	return {
		fetchAddressSuggestions,
		fetchAddressByCoordinates,
		isPrivateResidence,
		setTempTown,
		getAddressString,
		hasAvailableAddress,
		getRedirectUrl,
		setRedirectUrl,
		clearRedirectUrl
	};
};
