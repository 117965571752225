import { reactive, readonly } from 'vue';
import { takeaway } from '@/api';
import { IGetFilialsPayload } from '@/api/requests/takeaway/get-filials';
import { ClientService } from '@/services/client';
import { NEAR_CATEGORY } from '@/services/navigator/constants';
import { PaginationWrapperModel } from '@/utils/models';
import { IPaginationWrapper } from '@/types/interfaces/common';
import { IFilial } from '@/types/interfaces/navigator';
import {
	ISearchFilialsState,
	ISearchFilialsService
} from '@/types/interfaces/search';

const state = reactive<ISearchFilialsState<IFilial>>({
	searchFilials: new PaginationWrapperModel(),
	hasError: false
});

const setSearchFilials = (
	data: IPaginationWrapper<IFilial>
): PaginationWrapperModel<IFilial> => {
	state.searchFilials.addResults(data);
	return state.searchFilials;
};

const clearSearchFilials = (): void => state.searchFilials.clearResults();

const getAutocomplete = (
	value: string,
	brandId?: string
): Promise<string[]> => {
	const payload: IGetFilialsPayload = {
		search: value,
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		service: ClientService.state.service.id,
		limit: 3,
		brand_id: undefined,
		url: undefined
	};

	if (brandId) {
		payload.brand_id = brandId;
		payload.url = NEAR_CATEGORY.payload.url;
	}

	return takeaway.getFilials(payload).then(response => {
		return [...new Set(response.results.map(el => el.name))];
	});
};

const fetchSearchFilials = (
	search: string,
	brandId?: string
): Promise<PaginationWrapperModel<IFilial>> => {
	const payload: IGetFilialsPayload = {
		search,
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		service: ClientService.state.service.id,
		brand_id: undefined,
		url: undefined
	};

	if (brandId) {
		payload.brand_id = brandId;
		payload.url = NEAR_CATEGORY.payload.url;
	}

	return takeaway.getFilials(payload).then(response => {
		clearSearchFilials();
		return setSearchFilials(response);
	});
};

const loadMoreSearchFilials = async (
	search: string,
	brandId?: string
): Promise<PaginationWrapperModel<IFilial>> => {
	if (state.hasError) {
		return state.searchFilials;
	}

	if (state.searchFilials.page < state.searchFilials.lastPage) {
		const payload: IGetFilialsPayload = {
			search,
			town: ClientService.state.townId,
			coordinates: ClientService.state.coordinates,
			service: ClientService.state.service.id,
			page: state.searchFilials.page + 1,
			brand_id: undefined,
			url: undefined
		};

		if (brandId) {
			payload.brand_id = brandId;
			payload.url = NEAR_CATEGORY.payload.url;
		}

		try {
			const response = await takeaway.getFilials(payload);
			return setSearchFilials(response);
		} catch (error) {
			state.hasError = true;
			throw error;
		}
	}

	return state.searchFilials;
};

const SearchService: ISearchFilialsService<IFilial> = {
	state: readonly(state) as ISearchFilialsState<IFilial>,
	clearSearchFilials,
	getAutocomplete,
	fetchSearchFilials,
	loadMoreSearchFilials
};

export { SearchService };
export * from './search-products';
export * from './history';
