import { reactive, readonly, computed } from 'vue';
import {
	getActiveAddress,
	getDefaultAddress,
	getPriceLowestTariff
} from './functions';
import { IState, IDeliveryService, DeliveryZonePayload, KEYS } from './types';
import { createBaseService } from './base-delivery';
import { user, brandy, menuPromos } from '@/api';
import { ClientService } from '../client';
import { AuthService } from '../auth';
import { delivery } from '@/api';
import { NavigatorService } from '@/services/navigator';
import { VISIBLE_TOWNS } from '@/utils/constants';
import { getStorageParsedData } from '@/utils/functions';
import { ICfUserAddress } from '@/types/interfaces/chocofood';
import { ICoordinates } from '@/types/interfaces/common';
import { Subsidy } from '@/types/interfaces/menu';

const ACTIVE_ADDRESS_KEY = 'default.activeAddress';
const TEMP_ADDRESS_KEY = 'default.tempAddress';

const storage = window.localStorage;
const defaultAddress = getDefaultAddress();
const activeAddress = getStorageParsedData(ACTIVE_ADDRESS_KEY) || defaultAddress; // prettier-ignore
const tempAddress = getStorageParsedData(TEMP_ADDRESS_KEY) || defaultAddress;

const state = reactive<IState>({
	activeAddress,
	tempAddress,
	userAddressList: []
});

const BaseService = createBaseService(state);

/**
 * Возвращает текущий город из списка городов
 */
const getTown = computed(() => {
	return (
		VISIBLE_TOWNS.find(town => town.title === state.tempAddress.city) ||
		VISIBLE_TOWNS[0]
	);
});

const setActiveAddress = (address: ICfUserAddress) => {
	state.activeAddress = address;
	storage.setItem(ACTIVE_ADDRESS_KEY, JSON.stringify(address));
};

const setTempAddress = (address: Partial<ICfUserAddress>) => {
	state.tempAddress = {
		...state.tempAddress,
		...address
	};

	storage.setItem(TEMP_ADDRESS_KEY, JSON.stringify(state.tempAddress));
};

const fetchUserAddresses = async () => {
	const list = await user.getUserAddresses();
	return (state.userAddressList = list);
};

const initUnauthUserAddresses = () => {
	const address = getStorageParsedData(ACTIVE_ADDRESS_KEY) || defaultAddress;

	if (address.id) {
		state.userAddressList = [address];
		setActiveAddress(address);
	}
};

const initUserAddresses = async () => {
	if (!AuthService.isAuthorized()) {
		return initUnauthUserAddresses();
	}

	await DeliveryService.fetchUserAddresses();
	let address = state.activeAddress;

	// Если адрес не был создан на сервере
	if (address.id === -1) {
		address = await createAuthAddress(address);
		return setActiveAddress(address);
	}

	/**
	 * Если не подтверждали адрес, берем ближайший
	 */
	if (needToConfirmAddress()) {
		setActiveAddress(
			getActiveAddress(state.userAddressList, ClientService.state.coordinates)
		);
	}
};

const clearTempAddress = () => {
	state.tempAddress = defaultAddress;
	storage.removeItem(TEMP_ADDRESS_KEY);
};

const clearActiveAddress = () => {
	state.activeAddress = defaultAddress;
	storage.removeItem(ACTIVE_ADDRESS_KEY);
};

const clearAddresses = () => {
	clearActiveAddress();
	clearTempAddress();
};

const createUnauthAddress = (address: ICfUserAddress) => {
	address = {
		...address,
		id: -1
	};

	state.userAddressList = [address];
	setActiveAddress(address);
	return address;
};

const createAuthAddress = async (address: ICfUserAddress) => {
	const res = await user.createAddress(address);
	const sameAddressIndex = state.userAddressList.findIndex(a => {
		return a.id === res.id;
	});

	// если такой же адрес есть, обновляем его
	if (sameAddressIndex >= 0) {
		state.userAddressList[sameAddressIndex] = res;
	}
	// если нет, добавляем его в список
	else {
		state.userAddressList = [res, ...state.userAddressList];
		NavigatorService.clearAllFilials();
	}

	return res;
};

/**
 * Создает адрес пользователя и обновляет список адресов
 */
const createAddress = async (address: ICfUserAddress) => {
	let newAddress = address;

	if (AuthService.isAuthorized()) {
		newAddress = await createAuthAddress(address);
	} else {
		newAddress = createUnauthAddress(address);
	}

	setActiveAddress(newAddress);
	return newAddress;
};

const editUserAddress = async (id: number, address: ICfUserAddress) => {
	/**
	 * Если пользователь не авторизован
	 */
	if (!AuthService.isAuthorized()) {
		state.userAddressList = [address];
		setActiveAddress(address);
		return;
	}

	await user.updateUserAddress(address);
	state.userAddressList = state.userAddressList.map(el => {
		return el.id === id ? address : el;
	});

	clearTempAddress();
};

const deleteAddress = async (id: number) => {
	if (!AuthService.isAuthorized()) {
		state.userAddressList = [];
		clearAddresses();
		return;
	}

	await user.deleteAddress(id);
	state.userAddressList = state.userAddressList.filter(item => item.id !== id);

	if (state.activeAddress.id === id) {
		const address = getActiveAddress(
			state.userAddressList,
			ClientService.state.coordinates
		);

		setActiveAddress(address);
	}
};

const needToConfirmAddress = () => {
	return sessionStorage.getItem(KEYS.IS_ADDRESS_CONFIRMED_KEY) !== 'true';
};

const confirmAddress = () => {
	sessionStorage.setItem(KEYS.IS_ADDRESS_CONFIRMED_KEY, 'true');
};

const activeAddressCoordinates = computed(() => {
	if (BaseService.hasAvailableAddress()) {
		return state.activeAddress.coordinates;
	}

	return ClientService.state.coordinates || getTown.value.coordinates;
});

const fetchTariffs = async (originCoordinates: ICoordinates) => {
	let response = await delivery.getDeliveryTariffs({
		destination: activeAddressCoordinates.value,
		origin: originCoordinates
	});

	if (response.length && response.some(tariff => !tariff.is_fastest)) {
		response = [getPriceLowestTariff(response)];
	}

	return response;
};

const checkDeliveryZone = ({
	brandId,
	locationId,
	coordinates
}: DeliveryZonePayload) => {
	if (locationId) {
		return brandy
			.getInDeliveryZoneByLocation(locationId, coordinates)
			.then(res => res.data);
	}

	if (brandId) {
		return brandy
			.getInDeliveryZoneByBrand(brandId, coordinates)
			.then(res => res.data);
	}

	console.error('Не переданы параметры для проверки зоны доставки');

	return Promise.resolve({ is_in_delivery_zone: true, delivery_cost: 0 });
};

const getDeliverySubsidyDiscount = (sum: number, subsidy: Subsidy) => {
	let discountMax = 0;

	for (const threshold of subsidy.thresholds) {
		if (sum >= threshold.amount) {
			if (threshold.discount > discountMax) {
				discountMax = threshold.discount;
			}
		}
	}

	return discountMax;
};

export const DeliveryService: IDeliveryService = {
	...BaseService,
	state: readonly(state) as IState,
	getTown,
	activeAddressCoordinates,
	setTempAddress,
	setActiveAddress,
	fetchUserAddresses,
	initUserAddresses,
	clearTempAddress,
	clearActiveAddress,
	createAddress,
	editUserAddress,
	deleteAddress,
	needToConfirmAddress,
	confirmAddress,
	fetchTariffs,
	checkDeliveryZone,
	getDeliverySubsidyDiscount,
	fetchSubsidies: menuPromos.getSubsidiesByFilial
};
