import {
	IFilialExtended,
	ITerminal,
	ITerminalData,
	PaymentType
} from '@/types/interfaces/navigator';
import { IGetFilialResponse } from '@/api/types/interfaces/get-filial-response';
import { ApiProxyResponse } from '@/api/types/interfaces';

/**
 * Получение информации о терминале
 */
const getTerminalData = (terminal: ITerminal): ITerminalData => ({
	terminal_id: terminal.filial_id,
	service_percentage: terminal.service_percentage,
	service_type: terminal.service_type,
	linked_terminals: terminal.linked_terminals,
	is_check_pulling_enabled: terminal.is_check_pulling_enabled,
	exclude_from_terminal_linking: terminal.exclude_from_terminal_linking,
	has_tablet: terminal.has_tablet,
	vendor_delivery_enabled: terminal.vendor_delivery_enabled,
	vendor_delivery_cost: terminal.vendor_delivery_cost,
	vendor_max_sum: terminal.vendor_max_sum || 0,
	vendor_min_sum: terminal.vendor_min_sum || 0,
	delivery_state: terminal.delivery_state || 'open',
	delivery_delay_time: terminal.delivery_delay_time || 0,
	disable_chocobalance_button: terminal.disable_chocobalance_button || false,
	private_reward_enabled: terminal.private_reward_enabled || false,
	payment_types: getPaymentOptions(terminal)
});

/**
 * Объединяет location и included.takeaway || included.express в один филиал
 */
export default function (
	id: number,
	response: ApiProxyResponse<IGetFilialResponse>,
	additionalData: any = {}
): IFilialExtended {
	const location = response.data.location;
	const terminal = terminalSwitcher(id, response);

	if (!terminal) {
		throw new Error('Terminal is not found');
	}

	const terminalData = getTerminalData(terminal);

	return {
		...location,
		...terminalData,
		...additionalData
	};
}

function terminalSwitcher(
	id: number,
	response: ApiProxyResponse<IGetFilialResponse>
) {
	const takeaway = response.data.included.takeaway;
	const express = response.data.included.express;
	const delivery = response.data.included.delivery;

	switch (id) {
		case takeaway?.filial_id:
			return takeaway;
		case express?.filial_id:
			return express;

		default:
			return delivery;
	}
}

function getPaymentOptions(terminal: ITerminal): PaymentType[] {
	if (terminal.disable_payment_button) {
		return [];
	}

	const options: PaymentType[] = [];
	if (!terminal.disable_online_payment) {
		options.push('online');
	}

	if (terminal.is_cash_payment_type_enabled) {
		options.push('cash');
	}

	return options;
}
