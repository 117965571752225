import { RyadomCart } from '@/types/interfaces/ryadom';
import { ryadom } from '@/api';
import { ClientService } from '../client';
import { AuthService } from '../auth';
import { CartPositionModel } from '../cart';
import providers from '@/utils/plugins/di';

let hasBeenAuthorizedAtStart = AuthService.isAuthorized();

const isKnownIdentity = (): boolean => {
	return AuthService.isAuthorized() || providers.application.isWebView();
};

const isAuthorizedWrapper = (
	authorizedCb: () => any,
	unauthorizedCb: () => any
) => {
	return AuthService.isAuthorized() ? authorizedCb() : unauthorizedCb();
};

const fetchCart = (warehouseId: string): Promise<RyadomCart> => {
	const deviceId = ClientService.state.deviceId;
	return isAuthorizedWrapper(
		() => ryadom.getCartAuth(warehouseId),
		() => ryadom.getCart(warehouseId, deviceId)
	);
};
const resetCart = (warehouse_id: string) => {
	const deviceId = ClientService.state.deviceId;

	return isAuthorizedWrapper(
		() => {
			if (!hasBeenAuthorizedAtStart) {
				ryadom.clearCart(warehouse_id, deviceId);
				hasBeenAuthorizedAtStart = true;
			}

			return ryadom.clearCartAuth(warehouse_id);
		},
		() => ryadom.clearCart(warehouse_id, deviceId)
	);
};

const formatToRyadomCart = (products: CartPositionModel[]) => {
	return products.map(item => ({
		product_id: item.product_id.toString(),
		quantity: item.amount
	}));
};

const addToCart = (
	warehouse_id: string,
	filialId: number,
	positions: CartPositionModel[]
) => {
	const deviceId = ClientService.state.deviceId;
	const payload = {
		warehouse_id,
		terminal_id: filialId.toString(),
		products: formatToRyadomCart(positions)
	};

	return isAuthorizedWrapper(
		() => ryadom.addToRyadomAuth(payload),
		() => ryadom.addToCart(payload, deviceId)
	);
};

const RyadomService = {
	fetchCart,
	resetCart,
	addToCart,
	isKnownIdentity
};

export { RyadomService };
