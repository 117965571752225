import { client } from '../default.client';
import type {
	IAuthResponse,
	ApiProxyJSONApiResponse
} from '../types/interfaces';
import { AxiosResponse } from 'axios';
import { CLIENT_ID } from '@/utils/constants';

const AUTH_REQUEST = 'api/v2/oauth2/tokens';
const GET_TRACK_ID_REQUEST = 'api/v1/oauth2/tracks';

async function post<T>(url: string, data?: any, config?: any): Promise<T> {
	const { data: response } = await client.post(url, data, config);
	return response;
}

export const auth = {
	async authByCode(
		code: string,
		redirectUri: string,
		idempotencyKey?: string
	): Promise<IAuthResponse> {
		return post<IAuthResponse>(
			AUTH_REQUEST,
			{
				client_id: CLIENT_ID,
				grant_type: 'authorization_code',
				redirect_uri: redirectUri,
				code
			},
			{
				headers: {
					'X-Idempotency-key': idempotencyKey
				}
			}
		);
	},
	async authByTrackUd(trackId: string): Promise<IAuthResponse> {
		return post<IAuthResponse>(AUTH_REQUEST, {
			client_id: CLIENT_ID,
			grant_type: 'authorization_track_id',
			track_id: trackId
		});
	},
	async getTrackId(): Promise<
		AxiosResponse<ApiProxyJSONApiResponse<{ expires_in: number }>>
	> {
		return post(GET_TRACK_ID_REQUEST);
	}
};
