import axios from 'axios';
import axiosRetry from 'axios-retry';
import { stringify } from 'qs';
import { SentryService } from '@/services/sentry';
import i18n from '@/utils/plugins/i18n';

let chocofoodApi = 'https://takeaway-web.choco.kz/chocofood';
const isProduction = import.meta.env.VITE_ENV === 'production';

if (isProduction) {
	chocofoodApi = `${window.location.protocol}//${window.location.host}/chocofood`;
}

const baseURL = isProduction
	? chocofoodApi
	: import.meta.env.VITE_CHOCOFOOD_API;

const getLocaleHeader = (locale: string) => {
	return locale === 'kk' ? 'kz' : locale;
};

const chocofoodClient = axios.create({
	baseURL,
	paramsSerializer: params => stringify(params),
	transformRequest: [data => JSON.stringify(data)]
});

chocofoodClient.interceptors.request.use(
	config => {
		config.headers['Content-Type'] = 'application/json';
		config.headers['Locale'] = getLocaleHeader(i18n.global.locale.value);
		return config;
	},
	error => Promise.reject(error)
);

chocofoodClient.interceptors.response.use(
	response => response,
	error => {
		SentryService.captureApiException(error);
		return Promise.reject(error);
	}
);

axiosRetry(chocofoodClient, {
	retries: 3,
	retryDelay: retryCount => {
		return 1000 * retryCount;
	}
});

export { chocofoodClient };
